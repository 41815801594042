<style scoped lang="less">
    .product-module{
        // margin: 30px;
    }
</style>
<template>
    <div class="product-module">

        <div class="list-box">

            <div class="tab-box">
				<el-table :data="list" @row-click="handleRowClick" :row-key="getRowKey">
					<el-table-column width="40px">
						<template slot-scope="scope">
							<el-checkbox :value="scope.row.checked"></el-checkbox>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="页面名称" width="860px"></el-table-column>
				</el-table>
            </div>

        </div>

    </div>
</template>

<script>

export default {
    components: {
    },
    props: {
        
    },
    data () {
        return {
            type: '',
            list: []
        };
    },
    methods: {
		getRowKey(row){
			return row.id
		},
		handleRowClick(row, event, column){
			this.list = this.list.map(item=>{
				item.checked = item.id == row.id;
				return item;
			})
			this.$emit('change', {data:row, type:'functional', text: row.name})
		}
    },
    watch: {
    },
    mounted () {
        
    },
    created () {
		setTimeout(()=>{
			this.list = JSON.parse(JSON.stringify(this.$store.state.pageManageConfig.functionalPageList));
		}, 300)
    },
    dispatch () {
        
    }
};
</script>
