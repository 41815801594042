<style scoped lang="less">
    .search-box{
        margin-bottom: 20px;
    }
    .table-box{
        margin-bottom: 15px;
    }
</style>
<template>
    <el-dialog
        :visible="show"
        width='990px' 
        title="选择链接"
        :modal='showModal'
        @close="cancel">

        <el-tabs type="card" @tab-click="tabChange" :value="active">
            <el-tab-pane v-for="tab in list" :key="tab.id" :label="tab.name" :name="tab.key">
				<classify-tab v-if="tab.id === 2" @change="record=>dataChange(record, tab.key)"></classify-tab>
				<functional-tab v-if="tab.id === 3" @change="record=>dataChange(record, tab.key)"></functional-tab>
                <product-tab v-if="tab.id === 1" @change="record=>dataChange(record, tab.key)"></product-tab>
                <custom-tab v-if="tab.id === 4" @change="record=>dataChange(record, tab.key)"></custom-tab>
                <live-tab v-if="tab.id === 5 && showLiveTab" @change="record=>dataChange(record, tab.key)"></live-tab>
				<!-- <truntable v-if="tab.id === 6" @change="record=>dataChange(record, tab.key)"></truntable> -->
				<promotion v-if="tab.id === 7" @change="record=>dataChange(record, tab.key)"></promotion>
			</el-tab-pane>
        </el-tabs>

        
        <div slot="footer">
            <el-button type="primary" @click="btnSure">确定</el-button>
        </div>

    </el-dialog>
</template>
<script>

const tabs = [
	{
		id: 3,
		name: '系统页面',
		key: 'functional'
	},
    {
        id: 2,
        name: '商品分组',
        key: 'classify'
    },
	{
		id: 1,
		name: '商品',
		key: 'product',
	},
    {
        id: 4,
        name: '自定义页面',
        key: 'custom'
    },
	// {
	// 	id: 6,
	// 	name: '转盘抽奖',
	// 	key: 'truntable'
	// }
	{
		id: 7,
		name: '营销活动',
		key: 'promotion'
	}
]

import productTab from './components/product';
import classifyTab from './components/classify';
import functionalTab from './components/functional';
import customTab from './components/custom';
import liveTab from './components/live';
import truntable from './components/truntable';
import promotion from './components/promotion';
// import videoTab from './components/video';


export default {
    components: {
        productTab, //产品
        classifyTab, //营销活动
        functionalTab, //功能页面
        customTab, //自定义页面
        // videoTab, //视频模块
		liveTab,//直播模块
		truntable, //转盘抽奖
		promotion,//积攒赢好礼
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
		showLiveTab: {
			type: Boolean,
			default: false
		},
        showModal: {
			type: Boolean,
			default: true
		},
    },
    data () {
        return {
            show: false,
            list: tabs,
            data: {},
            active: 'custom',
        };
    },
    methods: {
        cancel(){
            this.$emit('cancel');
        },
        tabChange(record){
			console.log(record)
            this.active = record.name;
        },
        dataChange(data, key){
            this.data[key] = data;
        },
        btnSure(){
			console.log(this.data, this.active)
            if (!this.data[this.active]){
				this.$message({showClose: true,
					type: 'error',
					message: '您未选择任何链接!'
				});
                return false;
            }
			
            this.cancel();
            this.$emit('change', this.data[this.active])
        }
    },
    watch: {
        visible(value){
            this.show = value;
        },
    },
    mounted () {
		var list = this.list.filter(item=>{
			return item.id == 5
		})
        if (this.showLiveTab){
			
			if (!list.length){
				this.list.splice(4, 0, {
                    id: 5,
                    name: '直播管理',
                    key: 'live'
                })
			}
			
		}else{
			if (list.length){
				// this.list.push({
				// 	id: 5,
				// 	name: '直播管理',
				// 	key: 'live'
				// })
				this.list.splice(4, 1)
			}
		}
		
		// var truntable = this.list.filter(item=>{
		// 	return item.id == 6
		// })
		// if (!truntable.length){
		// 	this.list.push({
		// 		id: 6,
		// 		name: '转盘抽奖',
		// 		key: 'truntable'
		// 	})
		// }
		
    },
    created () {
		console.log(323)
    },
    dispatch () {
        
    }
};
</script>
