<style scoped lang="less">
	.brand-module{
    height: 100%;
    display: flex;
    flex-direction: column;

    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
}
.shop-name{
	display: flex;
}
.shop-name .img{
	flex: 0 0 auto;
}
.shop-name .name{
	flex: 1 1 auto;
	overflow: hidden;
	display: flex;
	align-items: center;
}
</style>
<template>
	<div class="brand-module">

		<div class="search">
			<span style="padding-right: 10px;">关键字：</span>
            <el-select v-model="searchType" style="width: 120px;margin-right: 10px;" size="mini">
                <el-option v-for="item in goodsSelect" :key="item.id" :label="item.type" :value="item.id"></el-option>
            </el-select>
            <el-input
                v-model="searchKey"
                placeholder="商品名称、编码、条码"
                style="width:250px;margin-right: 10px;"
                size="mini"
                clearable
            ></el-input>
            <span style="padding-right: 10px;">商品分类：</span>
            <el-cascader 
                v-model="categoryId" 
                :options="CategorySelectItemList" 
                :props="{ value: 'id', label: 'categoryName' ,children:'childCategoryList'}"
				children="childCategoryList" label="categoryName" value="id" style="width:300px;">
            </el-cascader>


			<el-button type="primary" size="mini" @click="pageNo=1;getData();">搜索</el-button>
		</div>

		<div class="tab-box">
			<el-table :data="data" v-loading="loading" @row-click="handleRowClick" size="mini" row-key="getRowKey">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column label="商品" width="500px">
					<template slot-scope="scope">
						<div class="shop-name" style="overflow: hidden;">
							<div class="img" style="width: 66px;height: 66px;">
								<img :src="scope.row.thumbImgUrl" style="display: block;width: 100%;" />
							</div>
							<div class="name" style="margin-left: 10px;">
								<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.goodsName}}</pre>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="价格" width="160px">
					<template slot-scope="scope">
						&yen;{{scope.row.salePrice}}
					</template>
				</el-table-column>
				<el-table-column prop="totalStock" label="库存" width="160px"></el-table-column>
			</el-table>
		</div>

		<div class="pagination">
			<el-pagination 
            :current-page="pageNo" 
            :total="total" 
            :page-size="pageSize" 
            @current-change="pageChange">
        </el-pagination>
		</div>

	</div>
</template>
<script>
    import apiList from '@/api/other'
	import config from '@/config/index'
    import {
        categoryList
	} from '@/api/goods'
	export default {
		components: {

		},
		props: {

		},
		data() {
			return {
				searchKey: '',
				pageNo: 1,
				pageSize: 5,
				total: 0,
				data: [],
				loading: false,
                imgUrl: config.IMG_BASE,
                searchType: 0,  //关键字类型
                goodsSelect: [{ //关键字类型
                        id: 0,
                        type: '商品名称'
                    },
                    {
                        id: 1,
                        type: '商品编码'
                    },
                    {
                        id: 2,
                        type: '商品条码'
                    }],
                categoryId:[],    //分类id
                CategorySelectItemList: [], //商品分类数据
            };
		},
		methods: {
			async getData() {
				const params = {
					pageNo: this.pageNo, // 页数
					pageSize: this.pageSize
                }
                
                if (this.searchType == 0) {
					params.goodsName = this.searchKey;
				} else if (this.searchType == 1) {
					params.goodsCode = this.searchKey; //编码
				} else if (this.searchType == 2) {
					params.barCode = this.searchKey;
                }
                if (this.categoryId.length) {
                    params.categoryId = this.categoryId[2];
                }
				this.loading = true;
				// const result = await apiList.activityproductmicrolist(params);
				const result = await apiList.activitycouponChoseproductlist(params);

				this.data = result.data.records;
				this.total = result.data.total;
				this.loading = false;

			},
			pageChange(value) {
				this.pageNo = value;
				this.getData();
			},
			getRowKey(row) {
				return row.id
			},
			handleRowClick(row, event, column) {

				this.data = this.data.map(item => {
					item.checked = item.id == row.id;
					return item;
				})

				this.$emit('change', {
					data: row,
					type: 'product',
					text: row.goodsName
				})
			},
            //格式化类目数据
			formatCategoryList(array) {
				for (let i = 0; i < array.length; i++) {
					if (array[i].categoryLevel == 3) {
						delete array[i].childCategoryList;
					} else {
						this.formatCategoryList(array[i].childCategoryList);
					}
				}
			},
            //获取类目
            async getCategoryList(){
                let result = await categoryList()
                this.formatCategoryList(result.data);
                this.CategorySelectItemList = result.data;
            }
		},
		watch: {},
		mounted() {
            this.getCategoryList();
		},
		created() {
			this.getData();
		},
		dispatch() {

		}
	};
</script>
