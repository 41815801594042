<style scoped lang="less">
.brand-module{
    height: 100%;
    display: flex;
    flex-direction: column;

    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
}
</style>
<template>
    <div class="brand-module">
        
        <div class="search">
			<span style="padding-right: 10px;">活动编码：</span>
            <el-input v-model="activityCode" style="width:250px;margin-right:10px" size="mini" clearable placeholder="请输入活动编码"/>
			<span style="padding-right: 10px;">活动名称：</span>
			<el-input v-model="activityName" style="width:250px;margin-right:10px" size="mini" clearable placeholder="请输入活动名称"/>
            <el-button type="primary" size="mini" @click="pageNo=1;getData();">搜索</el-button>
        </div>

        <div class="tab-box">
			<el-table :data="data" v-loading="loading" max-height="400px" @row-click="handleRowClick" :row-key="getRowKey">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="activityCode" label="活动编码" width="150px"></el-table-column>
				<el-table-column prop="activityName" label="活动名称" width="150px"></el-table-column>
				<el-table-column prop="" label="活动时间" width="360px">
					<template slot-scope="scope">
						<span>{{scope.row.beginDate}}</span>
						至
						<span>{{scope.row.endDate}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="活动状态" width="200px">
					<template slot-scope="scope">
						<span v-if="scope.row.activityStatus === 0">未开始</span>
						<span v-if="scope.row.activityStatus === 1">进行中</span>
						<span v-if="scope.row.activityStatus === 2">已结束</span>
					</template>
				</el-table-column>
			</el-table>
		</div>

        <div class="pagination">
            <el-pagination 
                :current-page="pageNo" 
                :total="total" 
                :page-size="pageSize" 
                @current-change="pageChange">
            </el-pagination>
        </div>

    </div>
</template>
<script>

import {chosepromotionActivity} from '../../../api/wyUsedInterface.js';
export default {
    components: {
        
    },
    props: {
        
    },
    data () {
        return {
			activityName:'',
			activityCode:'',
            pageNo: 1,
            pageSize: 10,
            total: 0,
            data: [],
            loading: false,
        };
    },
    methods: {
        async getData(){
            const params = {
                activityCode: this.activityCode,
				activityName:this.activityName,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }
            this.loading = true;

            const result = await chosepromotionActivity(params);
			//activityStatus  0-未开始 1-进行中 2-已结束
            this.data = result.data.records;
            this.total = result.data.total;
            this.loading = false;

        },
        pageChange(value){
            this.pageNo = value;
            this.getData();
        },
		getRowKey(row){
			return row.id
		},
		handleRowClick(row, event, column){
			this.data = this.data.map(item=>{
				item.checked = item.id == row.id;
				return item;
			})
			this.$emit('change', {data:row, type:'promotion', text: row.activityName})
		},
    },
    watch: {
    },
    mounted () {
        
    },
    created () {
        this.getData();
    },
    dispatch () {
        
    }
};
</script>
