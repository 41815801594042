<style scoped lang="less">
.brand-module{
    height: 100%;
    display: flex;
    flex-direction: column;

    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
}
</style>
<template>
    <div class="brand-module">
        
        <div class="search">
        	<span style="padding-right: 10px;">关键字：</span>
            <el-input v-model="keyWord" style="width:250px;margin-right:10px" clearable size="mini" placeholder="直播间Id/直播标题"/>
            <el-button type="primary" size="mini" @click="pageNo=1;getData();">搜索</el-button>
			<el-button type="primary" size="mini" @click="getNewBroad();">获取最新直播列表</el-button>
        </div>

        <div class="tab-box">
			<el-table :data="data" size="mini" v-loading="loading" @row-click="handleRowClick" :row-key="getRowKey">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="roomid" label="直播间ID" width="80px"></el-table-column>
				<el-table-column prop="anchorName" label="主播" width="120px"></el-table-column>
				<el-table-column prop="name" label="直播标题" width="290px"></el-table-column>
				<el-table-column label="直播时间" width="300px">
					<template slot-scope="scope">
						<span>{{scope.row.startTime}}</span>
						至
						<span>{{scope.row.endTime}}</span>
					</template>
				</el-table-column>
				<el-table-column label="直播状态" width="100px">
                    <template slot-scope="scope">
						<span v-if="scope.row.liveStatus == '101'">直播中</span>
						<span v-else-if="scope.row.liveStatus == '102'">未开始</span>
						<span v-else-if="scope.row.liveStatus == '103'">已结束</span>
						<span v-else-if="scope.row.liveStatus == '104'">禁播</span>
						<span v-else-if="scope.row.liveStatus == '105'">暂停</span>
						<span v-else-if="scope.row.liveStatus == '106'">异常</span>
						<span v-else>已过期</span>
					</template>
                </el-table-column>
			</el-table>
		</div>

        <div class="pagination">
            <el-pagination :current-page="pageNo" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
        </div>

    </div>
</template>
<script>

import {
		liveRoomList,
		liveRoomRefreshCache
	} from '@/api/goods'
import config from '@/config/index'
export default {
    components: {
        
    },
    props: {
        
    },
    data () {
        return {
            keyWord: '',
            pageNo: 1,
            pageSize: 10,
            total: 0,
            data: [],
            loading: false,
        };
    },
    methods: {
        async getData(){
			try {
				this.loading = true
			
				let data = {
                    keyWord: this.keyWord, // 搜索关键字
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				}
				let result = await liveRoomList(data);
				this.data = result.data.records || [];
				this.total = result.data.total;
				this.loading = false;
			
			} catch (e) {
				//TODO handle the exception
				console.log(e)
			} finally {
				this.loading = false
			}

        },
        pageChange(value){
            this.pageNo = value;
            this.getData();
        },
		// 获取最新直播
		async getNewBroad() {
			try {
				let result = await liveRoomRefreshCache()
				if (result.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功!'
					});
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e)
			} finally {
				this.getData()
			}
		
		},
		getRowKey(row){
			return row.id
		},
		handleRowClick(row, event, column){
			this.data = this.data.map(item=>{
				item.checked = item.id == row.id;
				return item;
			})
			this.$emit('change', {data:row, type:'live', text: row.name})
		},
    },
    watch: {
    },
    mounted () {
        
    },
    created () {
        this.getData();
    },
    dispatch () {
        
    }
};
</script>
