<style scoped lang="less">
.brand-module{
    height: 100%;
    display: flex;
    flex-direction: column;

    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
}
</style>
<template>
    <div class="brand-module">
        
        <div class="search">
        	<span style="padding-right: 10px;">分组名称：</span>
            <el-input v-model="name" style="width:250px;margin-right:10px" size="mini" clearable placeholder="分组名称"/>
            <el-button type="primary" size="mini" @click="pageNo=1;getData();">搜索</el-button>
        </div>

        <div class="tab-box">
			<el-table :data="data" v-loading="loading" @row-click="handleRowClick" :row-key="getRowKey">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="groupName" label="分组名称" width="700px"></el-table-column>
				<el-table-column prop="goodsCount" label="关联商品数量" width="200px"></el-table-column>
			</el-table>
		</div>

        <div class="pagination">
            <el-pagination 
                :current-page="pageNo" 
                :total="total" 
                :page-size="pageSize" 
                @current-change="pageChange">
            </el-pagination>
        </div>

    </div>
</template>
<script>

import {productGroupListData} from '../../../api/goods.js';
export default {
    components: {
        
    },
    props: {
        
    },
    data () {
        return {
            name: '',
            pageNo: 1,
            pageSize: 10,
            total: 0,
            data: [],
            loading: false,
        };
    },
    methods: {
        async getData(){
            const params = {
                name: this.name,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }

            this.loading = true;

            const result = await productGroupListData(params);

            this.data = result.data.records;
            this.total = result.data.total;
            this.loading = false;

        },
        pageChange(value){
            this.pageNo = value;
            this.getData();
        },
		getRowKey(row){
			return row.groupId
		},
		handleRowClick(row, event, column){
			this.data = this.data.map(item=>{
				item.checked = item.groupId == row.groupId;
				return item;
			})
			this.$emit('change', {data:row, type:'classify', text: row.groupName})
		},
    },
    watch: {
    },
    mounted () {
        
    },
    created () {
        this.getData();
    },
    dispatch () {
        
    }
};
</script>
