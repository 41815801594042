import request from '@/utils/request'

/*******商城装修************/
export const editSelectProduct = (data) => request('/pc/mall-shop-decorate/edit-select-product', data);
//自定义页面列表
export const mallShopDecorateList = (data) => request('/pc/mall-shop-decorate/index', data);
//通过分组选择商品
export const editSelectProductByGroup = (data) => request('/pc/mall-shop-decorate/edit-select-product-by-group', data);
//选择优惠券
export const editSelectCoupon = (data) => request('/pc/mall-shop-decorate/edit-select-coupon', data);
//选择积分兑换商品
export const editSelectPointExchange = (data) => request('/pc/mall-shop-decorate/edit-select-point-exchange', data);
//保存
export const editSave = (data) => request('/pc/mall-shop-decorate/edit-save', data);
//初始化
export const editInit = (data) => request('/pc/mall-shop-decorate/edit-init', data);
//页面列表
export const mallPageList = (data) => request('/pc/mall-shop-decorate/index', data);
//设为首页
export const setHome = (data) => request('/pc/mall-shop-decorate/set-home', data);
//删除页面
export const decorateDelete = (data) => request('/pc/mall-shop-decorate/delete', data);
//选择限时折扣活动
export const editSelectDiscount = (data) => request('/pc/mall-shop-decorate/edit-select-discount', data);
//限时折扣商品信息
export const editSelectDiscountProduct = (data) => request('/pc/mall-shop-decorate/edit-select-discount-product', data);
//大转盘活动列表
export const editSelectWheelSurf = (data) => request('/pc/mall-shop-decorate/edit-select-wheel-surf', data);
//社群专享商品列表
export const editSelectCommunityGroup = (data) => request('/pc/mall-shop-decorate/edit-select-community-group', data);