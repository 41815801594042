<style scoped lang="less">
    // .product-module{
    //     margin: 30px;
    //     height: 365px;
    //     overflow: auto;
    // }
</style>
<template>
    <div class="product-module">
        <div class="list-box">
             <div class="search" style="margin-bottom: 10px;">
                <span style="padding-right: 10px;">自定义页面名称：</span>
                <el-input v-model="pageName" style="width:250px;margin-right:10px" clearable size="mini" placeholder="页面名称"/>
                <el-button type="primary" size="mini" @click="pageNo=1;getData();">搜索</el-button>
            </div>
            <div class="tab-box">
                <el-table :data="list" v-loading="loading" @row-click="handleRowClick">
                    <el-table-column width="40px">
                        <template slot-scope="scope">
                            <el-checkbox v-model="scope.row.checked"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pageName" label="页面名称" width="860px"></el-table-column>
               </el-table>
            </div>
			
			<div class="pagination">
			    <el-pagination 
                    :current-page="pageNo" 
                    :total="total" 
                    :page-size="pageSize" 
                    @current-change="handleCurrentChange">
                </el-pagination>
			</div>
        </div>

    </div>
</template>

<script>
import {getPagedList} from '../../../api/cms.js';
export default {
    components: {
    },
    props: {
        
    },
    data () {
        return {
            type: '',
			pageNo: 1,
			pageSize: 10,
			total: 0,
			list: [],
            loading: false,
            pageName:'',
        };
    },
    methods: {
        async getData(){
            this.loading = true;
            const params = {
                pageName:this.pageName,
                pageNo:this.pageNo,
                pageSize:this.pageSize
            }
            const result = await getPagedList(params);
            
            this.list = result.data.records;
            console.log(this.list);
            this.total = result.data.total;
            this.loading = false;
        },
        // 切换显示条数
        handleSizeChange (val) {
            this.pageSize = val;
            this.getList();
        },
		handleCurrentChange(value){
		    this.pageNo = value;
		    this.getData();
		},
		handleRowClick(row, event){
			console.log(this.list)
			this.list = this.list.map(item=>{
				item.checked = item.id == row.id;
				console.log(item.checked)
				return item;
			})
			this.$emit('change', {data:row, type:'custom',text: row.pageName})
		}
        // selectionChange(selection){

        //     const name = (this.list.filter(item=>{
        //         return item.id == selection;
        //     })[0]).PageName

        //     this.$emit('change', {data: selection, type:'custom', text: '自定义页面>'+name})

        // }
    },
    watch: {
    },
    mounted () {
        
    },
    created () {
        this.getData();
    },
    dispatch () {
        
    }
};
</script>
